<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">库存</el-breadcrumb-item>
        <el-breadcrumb-item>月度库存结余</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-col :span="3" :offset="10">
        <el-dropdown>
          <el-button type="primary" size="small">
            导出下载<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="handleExport"
              >库存月结账下载</el-dropdown-item
            >
            <!-- <el-dropdown-item
              @click.native="$refs['monthly-export-dialog'].show(2)"
              >入库在途明细下载</el-dropdown-item
            > -->
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
    <!-- <el-row class="tab" style="margin-top: 40px;" >
            <el-tabs v-model="tabvalue">
                <el-tab-pane v-for="item in tabs" :key="item.key" :label="item.val" :name="item.key"></el-tab-pane>
            </el-tabs>
        </el-row> -->
    <!-- <el-row class="conten-row">
            <el-col :span="20">
            <el-form :inline="true" size="small">
                <el-form-item>
                    <el-select v-model="search.saas" placeholder="公司主体">
                        <el-option
                         v-for="item in sysConfig.Common.saas"
                        :key="item.key"
                        :value="item.val.id"
                        :label="item.val.name"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="tabvalue === '0'">
                    <el-select v-model="search.warehouseid" placeholder="请选择仓库">
                        <el-option
                        v-for="item in sysConfig.warehouselist"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                    v-model="search.yearmonth"
                    type="month"
                    value-format="yyyyMM"
                    placeholder="请选择年月"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="searchBalanceMonth()" size="small">搜索</el-button>
                    <el-button type="primary" @click="$refs['export-dialog'].show('X20', tabvalue)" size="small">导出下载</el-button>
                    <el-button type="primary" @click="downloadLink()" size="small">导出下载</el-button>
                </el-form-item>
            </el-form>
            </el-col>
            <el-col :span="2">
                <el-button type="text" v-if="search.showMoney === 0" @click="getInventoryBalanceMonth(1)">显示余额</el-button>
                <el-button type="text" v-if="search.showMoney === 1" @click="getInventoryBalanceMonth(0)">显示数量</el-button>
            </el-col>
        </el-row> -->
    <!-- <el-row v-if="search.showMoney === 0">
            <el-table stripe :data="balanceMonthlist" border style="width:100%">
                <el-table-column prop="_product_mfn" label="MFN"></el-table-column>
                <el-table-column prop="_warehouse" label="仓库"></el-table-column>
                <el-table-column prop="cost" label="成本价"></el-table-column>
                <el-table-column prop="qty" label="上期结存"></el-table-column>
                <el-table-column prop="qty_purchase" label="采购在途"></el-table-column>
                <el-table-column prop="qty_purchase_in" label="采购入库"></el-table-column>
                <el-table-column prop="qty_purchase_out" label="采购退货"></el-table-column>
                <el-table-column prop="qty_sales_out" label="销售出库"></el-table-column>
                <el-table-column prop="qty_sales_in" label="销售退货"></el-table-column>
                <el-table-column prop="qty_transfer_out" label="调拨出库"></el-table-column>
                <el-table-column prop="qty_transfer_in" label="调拨入库"></el-table-column>
                <el-table-column prop="qty_transfer" label="调拨在途"></el-table-column>
                <el-table-column prop="qty_disposal" label="报损"></el-table-column>
                <el-table-column prop="qty_adjustment" label="调整"></el-table-column>
                <el-table-column prop="qty_total" label="本期结存"></el-table-column>
                <el-table-column prop="day" label="进仓天数"></el-table-column>
            </el-table>
        </el-row>
        <el-row v-else-if="search.showMoney === 1">
            <el-table stripe :data="balanceMonthlist" border style="width:100%">
                <el-table-column prop="_product_mfn" label="MFN"></el-table-column>
                <el-table-column prop="_warehouse" label="仓库"></el-table-column>
                <el-table-column prop="cost" label="成本价"></el-table-column>
                <el-table-column prop="qty_amt" label="上期结存"></el-table-column>
                <el-table-column prop="qty_purchase_amt" label="采购在途"></el-table-column>
                <el-table-column prop="qty_purchase_in_amt" label="采购入库"></el-table-column>
                <el-table-column prop="qty_purchase_out_amt" label="采购退货"></el-table-column>
                <el-table-column prop="qty_sales_out_amt" label="销售出库"></el-table-column>
                <el-table-column prop="qty_sales_in_amt" label="销售退货"></el-table-column>
                <el-table-column prop="qty_transfer_out_amt" label="调拨出库"></el-table-column>
                <el-table-column prop="qty_transfer_in_amt" label="调拨入库"></el-table-column>
                <el-table-column prop="qty_transfer_amt" label="调拨在途"></el-table-column>
                <el-table-column prop="qty_disposal_amt" label="报损"></el-table-column>
                <el-table-column prop="qty_adjustment_amt" label="调整"></el-table-column>
                <el-table-column prop="qty_total_amt" label="本期结存"></el-table-column>
                <el-table-column prop="day" label="进仓天数"></el-table-column>
            </el-table>
        </el-row>
        <el-row class="pagination">
           <el-pagination
            :current-page.sync="pageData.current_page"
            :page-size="parseInt(pageData.per_page)"
            :page-sizes="[10,20,30,40,50]"
            layout="total,prev, pager, next,sizes,jumper"
            :total="pageData.total"
            @current-change="changePageHandler"
            @size-change="changeSizeHandler"
           >
           </el-pagination>
        </el-row> -->
    <!-- <monthly-export-dialog ref="monthly-export-dialog"></monthly-export-dialog> -->
    <xlsx-downloader ref="xlsx-downloader" type="FINANCE_MONTHLY_BALANCE" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import XlsxDownloader from '@/components/xlsx-downloader'
import { baseUrl } from '@/config/env'
import * as inventoryBalanceMonthApi from '@/api/inventoryMangement'
// import monthlyExportDialog from './monthly-export-dialog'

export default {
  components: {
    XlsxDownloader
  },
  data () {
    return {
      search: {
        yearmonth: '',
        showMoney: 0
      },
      balanceMonthlist: [],
      pageData: {
        current_page: 1,
        per_page: 10
      },
      tabvalue: '0',
      tabs: [
        {
          key: '0',
          val: '分仓'
        },
        {
          key: '1',
          val: '汇总'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    const date = new Date()
    this.search.yearmonth =
      date.getFullYear().toString() + ('0' + (date.getMonth() + 1)).slice(-2)
    this.getInventoryBalanceMonth()
  },
  watch: {
    tabvalue: function () {
      this.pageData.current_page = 1
      this.getInventoryBalanceMonth()
    }
  },
  methods: {
    handleExport () {
      this.$refs['xlsx-downloader'].show()
    },
    getInventoryBalanceMonth (showMoney = 0) {
      const self = this
      self.search.showMoney = showMoney
      const listJsondata = {
        warehouse_id: self.search.warehouseid,
        term: self.search.yearmonth,
        per_page: self.pageData.per_page,
        page: self.pageData.current_page,
        showMoney: self.search.showMoney,
        type: self.tabvalue,
        saas: self.search.saas
      }
      inventoryBalanceMonthApi
        .getInventoryBalanceMonthList(listJsondata)
        .then(data => {
          self.balanceMonthlist = []
          self.balanceMonthlist = data.data
          self.pageData.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    searchBalanceMonth () {
      this.pageData.current_page = 1
      this.getInventoryBalanceMonth()
    },
    changePageHandler (currentPage) {
      this.pageData.current_page = currentPage
      this.getInventoryBalanceMonth()
    },
    changeSizeHandler (size) {
      this.pageData.per_page = size
      this.getInventoryBalanceMonth()
    },
    downloadLink () {
      const url =
        baseUrl +
        'finance/inventory/exportMonthly?token=' +
        localStorage.getItem('token') +
        '&saas=' +
        this.search.saas +
        '&term=' +
        this.search.yearmonth
      window.open(url)
    }
  }
}
</script>
